import axios from 'axios'
import { handleResponse } from '../helpers/handle-response'
import { handleError } from '../helpers/handle-error'

class UserService {
  constructor() {
    this.getUsers = this.getUsers.bind(this)
    this.getUser = this.getUser.bind(this)
    this.createUser = this.createUser.bind(this)
  }

  getUsers() {
    const token = localStorage.getItem('token')
    console.log(token)
    return new Promise((resolve, reject) => {
      console.log('search users', process.env.REACT_APP_MY_VARIABLE_API_URL)
      axios
        .get(`${process.env.REACT_APP_MY_VARIABLE_API_URL}users/`, {
          headers: { Authorization: 'Bearer ' + token },
        })
        .then(handleResponse)
        .then((data) => {
          //console.log(data)
          resolve(data)
        })
        .catch((err) => {
          reject(handleError(err.response))
        })
    })
  }

  getUser(id) {
    const token = localStorage.getItem('token')
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_MY_VARIABLE_API_URL}users/${id}`, {
          headers: { Authorization: 'Bearer ' + token },
        })
        .then(handleResponse)
        .then((data) => {
          resolve(data)
        })
        .catch((err) => {
          reject(handleError(err.response))
        })
    })
  }

  getClientUser(id) {
    const token = localStorage.getItem('token')
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.REACT_APP_MY_VARIABLE_API_URL}clients/${id}`, {
          headers: { Authorization: 'Bearer ' + token },
        })
        .then(handleResponse)
        .then((data) => {
          resolve(data)
        })
        .catch((err) => {
          reject(handleError(err.response))
        })
    })
  }

  createUser(data) {
    const token = localStorage.getItem('token')
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${process.env.REACT_APP_MY_VARIABLE_API_URL}users/`,
          {
            ...data,
          },
          {
            headers: { Authorization: 'Bearer ' + token },
          },
        )
        .then(handleResponse)
        .then((data) => {
          //console.log(data)
          resolve(data)
        })
        .catch((err) => {
          reject(handleError(err.response))
        })
    })
  }

  updateUser(data) {
    const token = localStorage.getItem('token')
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${process.env.REACT_APP_MY_VARIABLE_API_URL}users/${data.id}`,
          {
            ...data,
          },
          {
            headers: { Authorization: 'Bearer ' + token },
          },
        )
        .then(handleResponse)
        .then((data) => {
          //console.log(data)
          resolve(data)
        })
        .catch((err) => {
          reject(handleError(err.response))
        })
    })
  }

  saveUser(data) {
    if (data.id) {
      return this.updateUser(data)
    } else {
      delete data.id
      return this.createUser(data)
    }
  }

  removeUser(id) {
    const token = localStorage.getItem('token')
    return new Promise((resolve, reject) => {
      axios
        .delete(`${process.env.REACT_APP_MY_VARIABLE_API_URL}users/${id}`, {
          headers: { Authorization: 'Bearer ' + token },
        })
        .then(handleResponse)
        .then((data) => {
          resolve(data)
        })
        .catch((err) => {
          reject(handleError(err.response))
        })
    })
  }
}

const userService = new UserService()
export default userService
