import React, { createContext, useContext, useEffect, useState } from 'react'
import { RolesTypes } from '../commons/constants'
import authenticationService from '../services/authentication.service'
import userService from '../services/user.service'

const noop = () => {}

const AuthContext = createContext({
  user: null,
  login: noop,
  logout: noop,
  type: null,
  flags: null,
})

const AuthenticationProvider = (props) => {
  const [user, setUser] = useState(null)
  const [flags, setFlags] = useState(null)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isLoading, setIsLoading] = useState(true) // Añadir el estado de carga

  const login = (user) => {
    setUser(user)
    setIsAuthenticated(true)
    setIsLoading(false) // La carga termina cuando se inicia sesión
  }
  const feature = (data) => {
    setFlags(data)
  }

  const logout = () => {
    authenticationService.signOut()
    setUser(null)
    setIsAuthenticated(false)
    setIsLoading(false) // La carga termina cuando se cierra sesión
  }

  useEffect(() => {
    const authUser = authenticationService.getAuthUser()
    if (authUser !== null) {
      setUser(authUser)
      setIsAuthenticated(true)
    }
    setIsLoading(false)
  }, [user])

  return (
    <AuthContext.Provider
      value={{ user, login, logout, isAuthenticated, flags, isLoading }}
      {...props}
    />
  )
}

const loginUser = ({ email, password, auth, client = false }) => {
  if (client) {
    return authenticationService.clientSignIn(email, password).then((user) => user)
  } else {
    return authenticationService.signIn(email, password).then((user) => user)
  }
}

const getUser = async ({ user }) => {
  if (user.role === RolesTypes.company) {
    return userService.getUser(user.id)
  } else {
    return userService.getClientUser(user.id)
  }
}

export default AuthenticationProvider

export const useAuthentication = () => useContext(AuthContext)
export { getUser, loginUser }
