import axios from 'axios'
import { handleResponse } from '../helpers/handle-response'
const API_URL = process.env.REACT_APP_MY_VARIABLE_API_URL

export function setSignInUser(userJson) {
  const token = userJson.token.replace(/^"(.*)"$/, '$1')
  if (userJson.companies && userJson.companies.length > 0) {
    userJson.companyPermissions = userJson.companies[0]
  } else {
    userJson.companyPermissions = null
  }
  localStorage.setItem('token', token)
  localStorage.setItem('user', JSON.stringify(userJson))
}
class AuthenticationService {
  constructor() {
    this.getAuthUser = this.getAuthUser.bind(this)
    this.getToken = this.getToken.bind(this)
    this.isAuthenticated = this.isAuthenticated.bind(this)
    this.signIn = this.signIn.bind(this)
    this.signOut = this.signOut.bind(this)
  }

  getAuthUser() {
    return localStorage.getItem('user')
  }

  getToken() {
    return localStorage.getItem('token')
  }

  isAuthenticated() {
    return this.getAuthUser() !== null
  }

  signIn(username, password) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_URL}login/`, { email: username, password: password })
        .then(handleResponse)
        .then((userJson) => {
          try {
            setSignInUser(userJson)
            resolve(userJson)
          } catch (err) {
            console.log('login error:', err)
            reject(err)
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  clientSignIn(username, password) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_URL}clients/login/`, { email: username, password: password })
        .then(handleResponse)
        .then((userJson) => {
          try {
            setSignInUser(userJson)
            resolve(userJson)
          } catch (err) {
            console.log('login error:', err)
            reject(err)
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  signUp(signUpData) {
    console.log(signUpData)
    const { firstName, lastName, password, email } = { ...signUpData }
    return new Promise((resolve, reject) => {
      axios
        .post(`${API_URL}users/`, {
          firstName,
          lastName,
          password,
          email,
          role: 'user',
        })
        .then(handleResponse)
        .then((userJson) => {
          try {
            setSignInUser(userJson)
            resolve(userJson)
          } catch (err) {
            console.log('signup error:', err)
            reject(err)
          }
        })
        .catch((err) => {
          reject(err.response.data.details)
        })
    })
  }

  signOut() {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
  }

  resetPassword(username) {
    return new Promise((resolve, reject) => {
      console.log('Resetting password', API_URL)
      axios
        .post(`${API_URL}users/reset-password/`, { email: username })
        .then(handleResponse)
        .then((data) => {
          console.log(data)
          resolve(data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  changePassword(token, password) {
    return new Promise((resolve, reject) => {
      console.log('Changing password', API_URL)
      axios
        .post(`${API_URL}users/change-password/`, { token: token, password: password })
        .then(handleResponse)
        .then((data) => {
          console.log('Password Changed', data)
          resolve(data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

const authenticationService = new AuthenticationService()
export default authenticationService
