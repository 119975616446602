import authenticationService from '../services/authentication.service'

export function handleResponse(response) {
  if (!response.status) {
    if ([401, 403].indexOf(response.status) !== -1) {
      // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
      authenticationService.signOut()
    }
    const error = (response.details && response.details) || response.statusText
    return Promise.reject(error)
  }

  return response.data
}
