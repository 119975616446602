import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useAuthentication } from 'src/session/AuthenticationProvider'

function PrivateRoute({ element }) {
  const { isAuthenticated, isLoading } = useAuthentication()
  if (isLoading) {
    return <div>Cargando...</div>
  }
  return isAuthenticated ? (
    <>
      {element}
      <Outlet />
    </>
  ) : (
    <Navigate to="/login" />
  )
}

export default PrivateRoute
