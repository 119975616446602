import authenticationService from '../services/authentication.service'

export function handleError(response) {
  if ([401].indexOf(response.status) !== -1) {
    console.log('entro logout')
    // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
    authenticationService.signOut()
  }
  let error = 'somethingWentWrong'

  //if(response.data.code === 'E_UNIQUE'){
  //error = response.data.message
  //}
  if (response.data && typeof response.data === 'string') {
    error = response.data
  }
  return error
}
